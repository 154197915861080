
export interface TextChunk {
    /**
     * The text content.
     */
    c: string
    /**
     * Whether the chunk should be highlighted or not.
     */
    h: boolean
}

export function createTextChunks(text: string | null, highlights: string[]) {
    if (!text) return []

    const result: TextChunk[] = []
    let currentIndex = 0

    function addChunk(text: string, isHighlighted: boolean) {
        if (!text) return
        result.push({
            c: text,
            h: isHighlighted,
        })
    }

    for (const highlight of highlights) {
        const startIndex = text.indexOf(highlight, currentIndex)
        if (startIndex > -1) {
            // add the non-highlighted text before the current highlight
            addChunk(text.substring(currentIndex, startIndex), false)
            // add the highlighted text
            addChunk(text.substring(startIndex, startIndex + highlight.length), true)
            // update the current index to the end of the current highlight
            currentIndex = startIndex + highlight.length
        }
    }

    // add any remaining text after the last highlight
    addChunk(text.substring(currentIndex), false)

    return result
}

/**
 * Sanitizes the text to remove any characters that could cause hydration mismatches.
 * @param text The text to sanitize.
 */
export function sanitizeText(text: string | null | undefined) {
    return text?.replace(/(\r\n|\n|\r)/gm, '')
}
