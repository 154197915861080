import {
    ApiModel,
    type ApiModelAttributes,
    type ApiModelEmbeds,
    type ApiModelRawData,
    type ApiModelStructure,
    type MaybeApiModelAttributes
} from '@composable-api/api.model'
import { ProductModel } from '@simploshop-models/product.model'
import { createTextChunks, type TextChunk } from '@simplo-sro/core-simploshop/app/utils/text'
import { ArticleModel } from '@simploshop-models/article.model'
import { BrandModel } from '@simploshop-models/brand.model'
import { CategoryModel } from '@simploshop-models/category.model'
import { SearchTermModel } from '@simploshop-models/custom/search-term.model'
import { errorLog } from '@composable-api-utils/logging'

interface SearchResultModelAttributes {
    [SearchResultModel.ATTR_ARTICLES]: SearchResourceArticles
    [SearchResultModel.ATTR_BRANDS]: SearchResourceBrands
    [SearchResultModel.ATTR_CATEGORIES]: SearchResourceCategories
    [SearchResultModel.ATTR_PHRASES]: SearchResourcePhrases
    [SearchResultModel.ATTR_PRODUCTS]: SearchResourceProducts
}

export class SearchResultModel extends ApiModel<SearchResultModelAttributes> {
    static readonly ATTR_ARTICLES = 'articles'
    static readonly ATTR_BRANDS = 'brands'
    static readonly ATTR_CATEGORIES = 'categories'
    static readonly ATTR_PHRASES = 'phrases'
    static readonly ATTR_PRODUCTS = 'products'

    get articles() {
        return this._getAttribute(SearchResultModel.ATTR_ARTICLES, {
            transform: (data) => new SearchResourceArticles(data, this),
        })
    }

    get brands() {
        return this._getAttribute(SearchResultModel.ATTR_BRANDS, {
            transform: (data) => new SearchResourceBrands(data, this),
        })
    }

    get categories() {
        return this._getAttribute(SearchResultModel.ATTR_CATEGORIES, {
            transform: (data) => new SearchResourceCategories(data, this),
        })
    }
    get phrases() {
        return this._getAttribute(SearchResultModel.ATTR_PHRASES, {
            transform: (data) => new SearchResourcePhrases(data, this),
        })
    }

    get products() {
        return this._getAttribute(SearchResultModel.ATTR_PRODUCTS, {
            transform: (data) => new SearchResourceProducts(data, this),
        })
    }

    areNoResults(): boolean {
        return !this.articles?.total
            && !this.brands?.total
            && !this.categories?.total
            && !this.phrases?.total
            && !this.products?.total
    }
}

// --------------------------------------------------------------------------------
// These models are not in response-serialization. Add when necessary.

type ApiModelAttributeHighlights<T extends ApiModel<any>> = {
    [key in keyof ApiModelAttributes<T>]?: string[]
}

interface SearchResourceAttributes<T extends SearchResourceItem<any>> {
    items: T[]
    total: number
    _highlights?: {
        [key: number]: ApiModelAttributeHighlights<T['model']>
    }
}

abstract class SearchResourceModel<T extends SearchResourceItem<any>> extends ApiModel<SearchResourceAttributes<T>> {
    static readonly ATTR_ITEMS = 'items'
    static readonly ATTR_TOTAL = 'total'
    static readonly ATTR_HIGHLIGHTS = '_highlights'

    protected searchResultModel: SearchResultModel

    constructor(data: ApiModelStructure<SearchResourceAttributes<T>, {}>, searchResultModel: SearchResultModel) {
        super(data)

        this.searchResultModel = searchResultModel
    }

    protected _items: T[] | null = null

    protected _getItems(transform: (data: ApiModelRawData<T['model']>) => T) {
        if (!this._items) {
            this._items = this._getAttribute(SearchResourceModel.ATTR_ITEMS, {
                transform: transform,
            })
        }
        return this._items
    }

    abstract getItems(): T[]

    get total() {
        return this._getAttribute(SearchResourceModel.ATTR_TOTAL)
    }

    _getHighlights(id: number | null | undefined) {
        if (id === null || id === undefined) return {}
        return this._getAttribute(SearchResourceModel.ATTR_HIGHLIGHTS)?.[id] ?? {}
    }
}

abstract class SearchResourceItem<T extends ApiModel<any, any>> {
    model: T
    protected highlights: ApiModelAttributeHighlights<T>
    protected embedHighlights: {
        [key in keyof ApiModelEmbeds<T>]?: Partial<Record<keyof MaybeApiModelAttributes<ApiModelEmbeds<T>[key]>, string[]>>
    } | null = null
    protected searchResultModel: SearchResultModel
    protected chunks: {
        [key in keyof ApiModelAttributes<T>]?: TextChunk[]
    } = {}
    protected embedChunks: {
        [key in keyof ApiModelEmbeds<T>]?: Partial<Record<keyof MaybeApiModelAttributes<ApiModelEmbeds<T>[key]>, TextChunk[]>>
    } = {}

    constructor(model: T, highlights: ApiModelAttributeHighlights<T>, searchResultModel: SearchResultModel) {
        this.model = model
        this.highlights = highlights
        this.searchResultModel = searchResultModel
    }

    protected _getChunks<TEmbedKey extends keyof ApiModelEmbeds<T>>(options: { model: TEmbedKey, attr: keyof MaybeApiModelAttributes<ApiModelEmbeds<T>[TEmbedKey]> }): TextChunk[]
    protected _getChunks(attr: keyof ApiModelAttributes<T>): TextChunk[]
    protected _getChunks<TEmbedKey extends keyof ApiModelEmbeds<T>>(param1: keyof ApiModelAttributes<T> | { model: TEmbedKey, attr: keyof MaybeApiModelAttributes<ApiModelEmbeds<T>[TEmbedKey]> }) {
        function isGettingChunksOfEmbed(val: typeof param1): val is { model: TEmbedKey, attr: keyof MaybeApiModelAttributes<ApiModelEmbeds<T>[TEmbedKey]> } {
            return typeof val === 'object'
        }

        if (isGettingChunksOfEmbed(param1)) {
            // initialize the highlights for the embedded resource
            if (!this.embedHighlights) {
                this.embedHighlights = this._initEmbeddedHighlights()
            }

            if (import.meta.dev && !this.embedHighlights[param1.model]) {
                errorLog(`[_getChunks]: Highlights for model '${param1.model as string}' not initialized. Add it to the \`_initEmbeddedHighlights\` method.`, this)
                return []
            }

            if (this.embedChunks[param1.model]?.[param1.attr]) return this.embedChunks[param1.model]?.[param1.attr]

            this.embedChunks[param1.model] ||= {}

            // @ts-ignore   TODO: fix type
            this.embedChunks[param1.model]![param1.attr] = createTextChunks(
                // @ts-ignore TODO: fix type
                this.model[param1.model]?.[param1.attr],    // calling a getter based on the embed key value
                this.embedHighlights[param1.model]?.[param1.attr] ?? []
            )

            return this.embedChunks[param1.model]![param1.attr]
        }

        if (this.chunks[param1]) return this.chunks[param1]

        this.chunks[param1] = createTextChunks(
            // @ts-expect-error accessing private property
            this.model._getAttribute(param1),
            this.highlights[param1] ?? []
        )

        return this.chunks[param1]
    }

    abstract _initEmbeddedHighlights(): NonNullable<typeof this.embedHighlights>
}

export class SearchResourceItemProduct extends SearchResourceItem<ProductModel> {
    _initEmbeddedHighlights() {
        return {
            [ProductModel.EMBED_BRAND]: this.searchResultModel.brands?._getHighlights(this.model.brand?.id) ?? {},
        }
    }

    getChunksName() {
        return this._getChunks(ProductModel.ATTR_NAME)
    }

    getChunksBrandName() {
        return this._getChunks({
            model: ProductModel.EMBED_BRAND,
            attr: BrandModel.ATTR_NAME,
        })
    }
}

class SearchResourceItemArticle extends SearchResourceItem<ArticleModel> {
    override _initEmbeddedHighlights() {
        return {}
    }

    getChunksName() {
        return this._getChunks(ArticleModel.ATTR_NAME)
    }
}

class SearchResourceItemBrand extends SearchResourceItem<BrandModel> {
    override _initEmbeddedHighlights() {
        return {}
    }

    getChunksName() {
        return this._getChunks(ArticleModel.ATTR_NAME)
    }
}

class SearchResourceItemCategory extends SearchResourceItem<CategoryModel> {
    override _initEmbeddedHighlights() {
        return {}
    }

    getChunksName() {
        return this._getChunks(CategoryModel.ATTR_NAME)
    }
}

class SearchResourceItemPhrase extends SearchResourceItem<SearchTermModel> {
    override _initEmbeddedHighlights() {
        return {}
    }

    getChunksTerm() {
        return this._getChunks(SearchTermModel.ATTR_TERM)
    }
}

class SearchResourceProducts extends SearchResourceModel<SearchResourceItemProduct> {
    getItems() {
        return this._getItems((data) =>
            new SearchResourceItemProduct(new ProductModel(data), this._getHighlights(data[ProductModel.ATTR_ID]), this.searchResultModel)
        )
    }
}

class SearchResourceArticles extends SearchResourceModel<SearchResourceItemArticle> {
    getItems() {
        return this._getItems((data) =>
            new SearchResourceItemArticle(new ArticleModel(data), this._getHighlights(data[ArticleModel.ATTR_ID]), this.searchResultModel)
        )
    }
}

class SearchResourceBrands extends SearchResourceModel<SearchResourceItemBrand> {
    getItems() {
        return this._getItems((data) =>
            new SearchResourceItemBrand(new BrandModel(data), this._getHighlights(data[BrandModel.ATTR_ID]), this.searchResultModel)
        )
    }
}

class SearchResourceCategories extends SearchResourceModel<SearchResourceItemCategory> {
    getItems() {
        return this._getItems((data) =>
            new SearchResourceItemCategory(new CategoryModel(data), this._getHighlights(data[CategoryModel.ATTR_ID]), this.searchResultModel)
        )
    }
}

class SearchResourcePhrases extends SearchResourceModel<SearchResourceItemPhrase> {
    getItems() {
        return this._getItems((data) =>
            new SearchResourceItemPhrase(new SearchTermModel(data), {}, this.searchResultModel)
        )
    }
}
