<template>
    <div class="sim-inline-product-card">
        <div class="sim-inline-product-card__img-box">
            <CoreImg
                :src="product.defaultImageUrl"
                :width="100"
                :alt="product.name"
                class="size-full object-contain"
            />

            <ProductDiscountBadge
                v-if="product?.isDiscounted()"
                class="absolute bottom-2 left-1/2 -translate-x-1/2"
                :percentage="product?.getDiscountPercents()"
            />
        </div>

        <!--  PRODUCT NAME  -->
        <div class="flex flex-col gap-1">
            <template v-if="searchResource">
                <BaseUiTextHighlight
                    v-if="searchResource?.model.brand?.name"
                    class="sim-text-extra-small sim-font-medium text-[#8090A7]"
                    :chunks="searchResource.getChunksBrandName()"
                />

                <BaseUiTextHighlight
                    class="sim-text-large sim-font-bold"
                    :chunks="searchResource.getChunksName()"
                />
            </template>

            <template v-else>
                <span v-if="product.brand?.name" class="sim-text-extra-small sim-font-medium text-[#8090A7]">
                    {{ product.brand?.name }}
                </span>

                <span class="sim-text-large sim-font-bold">
                    {{ product.name }}
                </span>
            </template>
        </div>

        <!--  PRODUCT PRICE  -->
        <div class="ml-auto flex flex-col items-end gap-1">
            <ProductPrice
                :product="product"
                type="no-discount-badge"
            />

            <ProductAvailability
                :product="product"
                size="sm"
                :show-stock-count="false"
            />
        </div>
    </div>
</template>

<script lang="ts" setup>
const {
    product: _product,
} = defineProps<{
    product: InstanceType<typeof ProductModel | typeof SearchResourceItemProduct>
}>()

const product = computed(() => _product instanceof ProductModel ? _product : _product.model)
const searchResource = computed(() => _product instanceof ProductModel ? null : _product)

</script>

<style lang="scss" scoped>

.sim-inline-product-card {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    color: $sim-c-primary-800;
    height: 6.5rem;

    transition: background-color $sim-trans-time-normal $sim-timing;

    &:hover {
        background-color: $sim-c-basic-200;
    }

    @include sim-text-normal-md;
    @include sim-font-regular;
}

.sim-inline-product-card__img-box {
    $size: 5rem;

    position: relative;
    width: $size;
    height: $size;
}

</style>
