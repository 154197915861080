import {
    ApiReactiveService,
    type ApiServiceUseFetchOptions
} from '@composable-api/service/api.reactive-service'
import type { ApiModel } from '@composable-api/api.model'
import { SearchResultModel } from '../models/search-result.model'

class SearchResultsApiReactiveService<M extends ApiModel> extends ApiReactiveService<M> {
    useGet<T extends boolean>(options?: ApiServiceUseFetchOptions<M, T>) {
        return this.useFetch({
            ...options,
            method: 'GET',
        })
    }
}

export default function useSearchResultsApiService() {
    return new SearchResultsApiReactiveService({
        endpoint: '/search-results',
    }, SearchResultModel)
}
