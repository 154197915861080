<script lang="tsx">
import { defineComponentBaseUiTextHighlight } from '@core/app/components/base/ui/BaseUiTextHighlight.vue'

export default defineComponentBaseUiTextHighlight()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiTextHighlight" as *;

@include highlight {
    position: relative;
    padding: 0.1rem;
    margin: -0.1rem;

    background: linear-gradient(90deg, #ECF4FF 50%, transparent 50%) no-repeat 75% 0;
    background-size: 200% 100%;

    box-decoration-break: clone;

    animation: scale-left-to-right 800ms $sim-timing both;
    animation-delay: 200ms;
}

@keyframes scale-left-to-right {
    from {
        background-position: 100% 0;
    }

    to {
        background-position: 0 0;
    }
}

</style>
