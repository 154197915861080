<script lang="tsx">
import type { SlotsType } from 'vue'
import type { TextChunk } from '../../../utils/text'

export type BaseUiTextHighlightProps<Colors extends string, Variants extends string, Sizes extends string> = {
    color?: Colors
    variant?: Variants
    size?: Sizes

    chunks: TextChunk[]
}

type BaseUiTextHighlightSlots = {}

type ComponentOptions = {}

export function defineComponentBaseUiTextHighlight<
    Colors extends string,
    Variants extends string,
    Sizes extends string,
>(options?: ComponentOverrideOptions<ComponentOptions, BaseUiTextHighlightProps<Colors, Variants, Sizes>, BaseUiTextHighlightSlots>) {
    return defineComponent(
        (props:  BaseUiTextHighlightProps<Colors, Variants, Sizes>, ctx) => {
            return () => (
                <span class={['sim-highlight', {
                    [`c-${props.color}`]: props.color,
                    [`v-${props.variant}`]: props.variant,
                    [`s-${props.size}`]: props.size,
                }]}>
                    {props.chunks.map(chunk => {
                        if (chunk.h) return (<em>{chunk.c}</em>)
                        return chunk.c
                    })}
                </span>
            )
        },
        {
            props: {
                color: {
                    // @ts-ignore
                    type: String as PropType<BaseUiTextHighlightProps<Colors, Variants, Sizes>['color']>,
                    default: options?.props?.color?.default,
                    required: options?.props?.color?.required ?? false,
                },
                variant: {
                    // @ts-ignore
                    type: String as PropType<BaseUiTextHighlightProps<Colors, Variants, Sizes>['variant']>,
                    default: options?.props?.variant?.default,
                    required: options?.props?.variant?.required ?? false,
                },
                size: {
                    // @ts-ignore
                    type: String as PropType<BaseUiTextHighlightProps<Colors, Variants, Sizes>['size']>,
                    default: options?.props?.size?.default,
                    required: options?.props?.size?.required ?? false,
                },

                chunks: {
                    type: Array as PropType<BaseUiTextHighlightProps<Colors, Variants, Sizes>['chunks']>,
                    default: options?.props?.chunks?.default,
                    required: options?.props?.chunks?.required ?? true,
                },
            },
            slots: Object as SlotsType<BaseUiTextHighlightSlots>,
            emits: {},
        }
    )
}

export default defineComponentBaseUiTextHighlight()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiTextHighlight.scss" as *;

</style>
